import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import ArrowIcon from 'components/UI/Icons/ArrowIcon'
import React, { useEffect, useMemo, useState } from 'react'
import ScreenTypeSelector from 'screens/Screens/NewScreens/component/ScreenTypeSelector'
import { colors } from 'shared/style/colors'
import ContentTypeSelector from '../../ContentTypeSelector'
import AddTranslationButton from './AddTranslationButton'
import { GET_ISO_LANGUAGES } from 'services/languages/graphql'
import TranslationInput from './TranslationInput'
import { useQuery } from '@apollo/client'
import ScreenContentTypeSelector from 'screens/Screens/NewScreens/component/ScreenContentTypeSelector'

const BaseSettings = ({
  place,
  screenType,
  type,
  title,
  description,
  isAudio,
  isShufflable,
  toggleShufflable,
  toggleAudio,
  toggleScreenType,
  toggleContentType,
  toggleScreenContentType,
  handleInputTitle,
  handleInputDescription,
  titleTranslates,
  addTitleTranslation,
  handleTitleTranslation,
  handleRemoveTitleTranslation,
  descriptionTranslates,
  addDscrTranslation,
  handleDscrTranslation,
  handleRemoveDscrTranslation,
}) => {
  const [hover, setHover] = useState(false)
  const [availableTitleLocales, setAvailableTitleLocales] = useState([])
  const [availableDscrLocales, setAvailableDscrLocales] = useState([])

  const audioValue = useMemo(() => {
    if (isAudio === null) return isAudio

    if (isAudio) return 1

    return 0
  }, [isAudio])

  const shuffleValue = useMemo(() => {
    if (isShufflable === null) return isShufflable

    if (isShufflable) return 1

    return 0
  }, [isShufflable])

  const updateAudio = e => {
    const value = Boolean(Number(e))

    toggleAudio(value)
  }

  const updateShuffle = e => {
    const value = Boolean(Number(e))

    toggleShufflable(value)
  }

  const { data, loading } = useQuery(GET_ISO_LANGUAGES, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (data && !loading) {
      setAvailableTitleLocales(
        (data.languages || []).filter(
          item =>
            !titleTranslates.some(selected => selected.locale === item.code),
        ),
      )
    }
  }, [data, loading, titleTranslates])

  useEffect(() => {
    if (data && !loading) {
      setAvailableDscrLocales(
        (data.languages || []).filter(
          item =>
            !descriptionTranslates.some(
              selected => selected.locale === item.code,
            ),
        ),
      )
    }
  }, [data, loading, descriptionTranslates])

  return (
    <AccordionItem border='none' key='base-settings'>
      {({ isExpanded }) => (
        <Flex
          bg='white'
          w='100%'
          borderRadius='8px'
          direction='column'
          border={
            hover || isExpanded
              ? `1px solid ${colors['primary-violet-100']}`
              : `1px solid ${colors['primary-gray-30']}`
          }
        >
          <AccordionButton
            _hover={{
              bg: 'white',
            }}
            p='8px 44px'
            m='0'
            borderRadius='8px'
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            outline='none'
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Flex direction='column' flex={1} gap='12px'>
              <Heading
                size='poppins24'
                variant='primary-black-text'
                color={
                  hover || isExpanded
                    ? colors['primary-blue-100']
                    : colors['primary-black-text']
                }
                lineHeight='38px'
                letterSpacing='0'
                textAlign='left'
              >
                Core Settings
              </Heading>
            </Flex>

            {isExpanded ? (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box transform='rotate(180deg)'>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            ) : (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            )}
          </AccordionButton>
          <AccordionPanel>
            <Flex direction='column'>
              <Flex w='100%' gap='48px'>
                <Flex direction='column' w='50%'>
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                    w='100%'
                    mb='12px'
                  >
                    Screen place type
                  </Text>

                  <Box mb='24px' w='100%'>
                    <ScreenTypeSelector
                      toggleScreenType={toggleScreenType}
                      place={place}
                      isModal
                    />
                  </Box>
                </Flex>

                <Flex direction='column' w='50%'>
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                    w='100%'
                    mb='12px'
                  >
                    Screen screen content type
                  </Text>

                  <Box mb='24px' w='100%'>
                    <ScreenContentTypeSelector
                      toggleScreenContentType={toggleScreenContentType}
                      screenType={screenType}
                      isModal
                    />
                  </Box>
                </Flex>

                <Flex direction='column' w='50%'>
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                    w='100%'
                    mb='12px'
                  >
                    Section content type
                  </Text>

                  <Box mb='24px' w='100%'>
                    <ContentTypeSelector
                      toggle={toggleContentType}
                      type={type}
                      isModal
                    />
                  </Box>
                </Flex>

                <Flex direction='column' w='50%'>
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                    w='100%'
                    mb='12px'
                  >
                    isAudio
                  </Text>

                  <Box mb='24px' w='100%'>
                    <RadioGroup
                      name='is-audio'
                      onChange={updateAudio}
                      value={audioValue}
                    >
                      <Stack direction='row'>
                        <Radio colorScheme='green' value={1}>
                          Yes
                        </Radio>
                        <Radio colorScheme='red' value={0}>
                          No
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Box>
                </Flex>

                <Flex direction='column' w='50%'>
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                    w='100%'
                    mb='12px'
                  >
                    Shufflable
                  </Text>

                  <Box mb='24px' w='100%'>
                    <RadioGroup
                      name='shufflable'
                      onChange={updateShuffle}
                      value={shuffleValue}
                    >
                      <Stack direction='row'>
                        <Radio colorScheme='green' value={1}>
                          Yes
                        </Radio>
                        <Radio colorScheme='red' value={0}>
                          No
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Box>
                </Flex>
              </Flex>

              <Flex w='100%' gap='48px'>
                <Flex direction='column' w='50%'>
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                    w='100%'
                    mb='12px'
                  >
                    Section title
                  </Text>

                  <Input
                    type='text'
                    placeholder='Title'
                    variant='loginField'
                    value={title}
                    onChange={handleInputTitle}
                    mb={title ? '8px' : '24px'}
                  />

                  {title && (
                    <Box mb='24px'>
                      {titleTranslates.map((item, index) => (
                        <TranslationInput
                          key={`${item.locale}-${item.value}`}
                          availableLocales={availableTitleLocales}
                          handleTranslation={handleTitleTranslation}
                          handleRemoveTranslation={handleRemoveTitleTranslation}
                          item={item}
                          index={index}
                          location='title'
                        />
                      ))}

                      <AddTranslationButton add={addTitleTranslation} />
                    </Box>
                  )}
                </Flex>

                <Flex direction='column' w='50%'>
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                    w='100%'
                    mb='12px'
                  >
                    Section description
                  </Text>

                  <Input
                    type='text'
                    placeholder='Description'
                    variant='loginField'
                    value={description}
                    onChange={handleInputDescription}
                    mb={description ? '8px' : '24px'}
                  />

                  {description && (
                    <Box mb='24px'>
                      {descriptionTranslates.map((item, index) => (
                        <TranslationInput
                          key={`${item.locale}-${item.value}`}
                          availableLocales={availableDscrLocales}
                          handleTranslation={handleDscrTranslation}
                          handleRemoveTranslation={handleRemoveDscrTranslation}
                          item={item}
                          index={index}
                          location='description'
                        />
                      ))}

                      <AddTranslationButton add={addDscrTranslation} />
                    </Box>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  )
}

export default BaseSettings
