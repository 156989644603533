import axiosApiInstance from 'utils/axios-instance'

export const fetchBooksByGenreId = ({ genre_id }) =>
  axiosApiInstance.get(`books/genres/${genre_id}`)

export const updateBook = book => axiosApiInstance.put('books', book)

export const getAllBooks = () => axiosApiInstance.get('books/admin/full')

export const setReleaseBookDate = book =>
  axiosApiInstance.post('queue/books/approve', book)

export const uploadBookFullCover = ({ book_id, formdata }) =>
  axiosApiInstance.post(`file/upload/book/${book_id}/cover/full`, formdata)

export const updateBookCover = ({ formdata, book_id }) =>
  axiosApiInstance.post(`file/upload/book/${book_id}`, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const updateBookBgCover = ({ formdata, book_id }) =>
  axiosApiInstance.post(
    `file/upload/book/${book_id}/cover/background`,
    formdata,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )

export const uploadChapterAudio = ({ chapter_id, formdata }) =>
  axiosApiInstance.post(`file/upload/chapter/${chapter_id}/audio`, formdata)

export const removeChapterAudio = ({ chapter_id, code }) =>
  axiosApiInstance.delete(`file/chapter/${chapter_id}/locale/${code}`)
