import React from 'react'
import { BOOK_ACTIONS } from '..'
import { Breadcrumb, BreadcrumbItem, Button } from '@chakra-ui/react'

const ModalNavigation = ({ currentFlow, handleChangeFlow }) => {
  return (
    <Breadcrumb fontWeight='medium' fontSize='sm'>
      <BreadcrumbItem>
        <Button
          variant='defaultButton'
          isDisabled={currentFlow === BOOK_ACTIONS.info}
          onClick={() => handleChangeFlow(BOOK_ACTIONS.info)}
        >
          {BOOK_ACTIONS.info.charAt(0).toUpperCase() +
            BOOK_ACTIONS.info.slice(1)}
        </Button>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Button
          variant='defaultButton'
          isDisabled={currentFlow === BOOK_ACTIONS.fields}
          onClick={() => handleChangeFlow(BOOK_ACTIONS.fields)}
        >
          {BOOK_ACTIONS.fields.charAt(0).toUpperCase() +
            BOOK_ACTIONS.fields.slice(1)}
        </Button>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Button
          variant='defaultButton'
          isDisabled={currentFlow === BOOK_ACTIONS.recommendations}
          onClick={() => handleChangeFlow(BOOK_ACTIONS.recommendations)}
        >
          {BOOK_ACTIONS.recommendations.charAt(0).toUpperCase() +
            BOOK_ACTIONS.recommendations.slice(1)}
        </Button>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Button
          variant='defaultButton'
          isDisabled={currentFlow === BOOK_ACTIONS.author}
          onClick={() => handleChangeFlow(BOOK_ACTIONS.author)}
        >
          {BOOK_ACTIONS.author.charAt(0).toUpperCase() +
            BOOK_ACTIONS.author.slice(1)}
        </Button>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Button
          variant='defaultButton'
          isDisabled={currentFlow === BOOK_ACTIONS['resizable cover']}
          onClick={() => handleChangeFlow(BOOK_ACTIONS['resizable cover'])}
        >
          {BOOK_ACTIONS['resizable cover'].charAt(0).toUpperCase() +
            BOOK_ACTIONS['resizable cover'].slice(1)}
        </Button>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Button
          variant='defaultButton'
          isDisabled={currentFlow === BOOK_ACTIONS['bg cover']}
          onClick={() => handleChangeFlow(BOOK_ACTIONS['bg cover'])}
        >
          {BOOK_ACTIONS['bg cover'].charAt(0).toUpperCase() +
            BOOK_ACTIONS['bg cover'].slice(1)}
        </Button>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Button
          variant='defaultButton'
          isDisabled={currentFlow === BOOK_ACTIONS.audio}
          onClick={() => handleChangeFlow(BOOK_ACTIONS.audio)}
        >
          {BOOK_ACTIONS.audio.charAt(0).toUpperCase() +
            BOOK_ACTIONS.audio.slice(1)}
        </Button>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}

export default ModalNavigation
