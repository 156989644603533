import { useLazyQuery } from '@apollo/client'
import { Flex, Heading, Select, Text } from '@chakra-ui/react'
import { API_V2 } from 'constants/apollo'
import React, { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { GET_ALL_SECTIONS } from 'services/sections/graphql'
import { colors } from 'shared/style/colors'

const TagsSummary = ({
  selectedTags,
  handleRemoveTag,
  handleOnDragTags,
  shouldShowTagsSectionPicker,
  updateTagSectionRelations,
  targetSectionForTags,
}) => {
  const [sections, setSection] = useState([])

  const initialVariables = useMemo(() => {
    const base = {
      pagination: {
        offset: 0,
        count: 9999,
      },
      section: {},
    }

    return base
  }, [])

  const [loadSections] = useLazyQuery(GET_ALL_SECTIONS, {
    fetchPolicy: 'cache-and-network',
    context: {
      clientName: API_V2,
    },
  })

  const loadAllSections = async () => {
    const {
      data: { items },
    } = await loadSections({
      variables: initialVariables,
    })

    setSection(items.sections)
  }

  const handlePickSection = (e, item) => {
    updateTagSectionRelations(e.target.value, item.tag_id)
  }

  useEffect(() => {
    if (shouldShowTagsSectionPicker) {
      loadAllSections()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowTagsSectionPicker])

  return (
    <Flex gap='20px'>
      <Heading
        size='poppins20'
        variant='primary-black-text'
        lineHeight='38px'
        letterSpacing='0'
        textAlign='left'
        w='120px'
        minW='120px'
      >
        Tags
      </Heading>

      <Flex mb='24px'>
        <DragDropContext onDragEnd={handleOnDragTags}>
          <Droppable droppableId='tags' style={{ transform: 'none' }}>
            {provided => (
              <Flex
                gap='10px'
                direction='column'
                wrap='wrap'
                {...provided.droppableProps}
                ref={provided.innerRef}
                userSelect='none'
              >
                {selectedTags.map((item, index) => (
                  <Draggable
                    key={item.tag_id}
                    draggableId={item.tag_id}
                    index={index}
                  >
                    {provided => (
                      <Flex gap='10px'>
                        <Flex
                          gap='8px'
                          border={`1px solid ${colors['primary-gray-30']}`}
                          p='8px 12px'
                          boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                          borderRadius='8px'
                          cursor='pointer'
                          _hover={{
                            bg: 'rgba(36, 28, 88, 0.03)',
                          }}
                          onClick={() => handleRemoveTag(item)}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Flex w='100%' gap='10px' direction='row'>
                            <Text
                              size='poppins14'
                              variant='primary-black-text'
                              fontWeight={600}
                              whiteSpace='nowrap'
                            >
                              {item.title}
                            </Text>
                          </Flex>
                        </Flex>

                        {shouldShowTagsSectionPicker && (
                          <Select
                            placeholder='Select option'
                            onChange={e => handlePickSection(e, item)}
                            value={targetSectionForTags[item.tag_id] || ''}
                          >
                            {sections
                              .filter(item => item.contentType === 'BOOKS')
                              .map(section => {
                                return (
                                  <option key={section.id} value={section.id}>
                                    {section.title} - {section.id}
                                  </option>
                                )
                              })}
                          </Select>
                        )}
                      </Flex>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </Flex>
    </Flex>
  )
}

export default TagsSummary
