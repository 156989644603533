import './styles.scss'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createUserThunk, fetchUsersThunk } from 'redux/slices/users/thunks'
import { getUsersList } from 'redux/slices/users/selectors'
import { getBookPremiumTypes } from 'redux/slices/types/selectors'
import { useDispatch, useSelector } from 'react-redux'
import BookCreatorModal from '../../components/BookCreatorModal'
import NewWriterModal from '../../components/NewWriterModal'
import generateEmail from 'utils/generateEmail'
import generatePassword from 'utils/generatePassword'
import {
  setReleaseBookDateThunk,
  updateBookThunk,
} from 'redux/slices/books/thunks'
import moment from 'moment'
import { ALERT_FADE_TIMEOUT } from 'constants/ui'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { GET_FULL_BOOK } from 'services/book/graphql'
import ModalNavigation from './ModalNavigation'
import BookInfo from './BookInfo'
import Recommendations from './Recommendations'
import BookFields from './BookFields'
import Author from './Author'
import BookCover from 'screens/Books/components/BookCover'
import { apolloClient } from 'app/hocs/with-apollo'
import Audio from 'screens/Books/components/Audio'
import BookBgCover from 'screens/Books/components/BookBgCover'

export const BOOK_ACTIONS = {
  info: 'info',
  fields: 'fields',
  recommendations: 'recommendations',
  author: 'author',
  'resizable cover': 'resizable cover',
  'bg cover': 'bg cover',
  audio: 'audio',
}

const BooksModal = ({ book: selectedBook, hideBookModal, isOpen }) => {
  const dispatch = useDispatch()

  const bookPremiumTypes = useSelector(getBookPremiumTypes)
  const usersList = useSelector(getUsersList)

  const { data: book, loading: isBookLoading } = useQuery(GET_FULL_BOOK, {
    variables: {
      book_id: selectedBook.book_id,
    },
    fetchPolicy: 'cache-and-network',
  })

  const [currentFlow, sentCurrentFlow] = useState('info')
  const [isShowCreatorUpdateModal, setIsShowCreatorUpdateModal] =
    useState(false)
  const [isShowWriterCreateModal, setIsShowWriterCreateModal] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [isShowSuccessAlert, setIsShowSuccessAlert] = useState(false)
  const [isShowErrorAlert, setIsShowErrorAlert] = useState(false)
  const [isUserCreating, setIsUserCreating] = useState(false)

  const handleChangeFlow = value => {
    sentCurrentFlow(value)
  }

  const filteredUsersList = useMemo(
    () =>
      usersList.filter(
        user =>
          (user?.first_name
            ?.toLowerCase()
            .includes(searchFilter.toLowerCase()) ||
            user?.last_name
              ?.toLowerCase()
              .includes(searchFilter.toLowerCase())) &&
          book?.book[0].creator_id?.user_id !== user.user_id,
      ),
    [book, searchFilter, usersList],
  )

  const handleSearchChange = value => {
    setSearchFilter(value)
  }

  const handleHideAlert = useCallback(() => {
    setIsShowErrorAlert(false)
    setIsShowSuccessAlert(false)
  }, [])

  const handleShowCreatorUpdateModal = useCallback(() => {
    setIsShowCreatorUpdateModal(true)
  }, [])

  const handleHideCreatorUpdateModal = useCallback(() => {
    setIsShowCreatorUpdateModal(false)
    setSearchFilter('')
  }, [])

  const handleShowWriterCreateModal = useCallback(() => {
    setIsShowWriterCreateModal(true)
  }, [])

  const handleHideWriterCreateModal = useCallback(() => {
    setIsShowWriterCreateModal(false)
    handleShowCreatorUpdateModal()
  }, [handleShowCreatorUpdateModal])

  const handleBookCreatorUpdate = useCallback(
    async ({ user_id, first_name, last_name }) => {
      const updatedBook = {
        book_id: selectedBook.book_id,
        creator_id: {
          user_id,
          first_name,
          last_name,
        },
      }
      await dispatch(updateBookThunk(updatedBook))
      await apolloClient.query({
        query: GET_FULL_BOOK,
        variables: {
          book_id: selectedBook.book_id,
        },
        fetchPolicy: 'network-only',
      })
      handleHideCreatorUpdateModal()
    },
    [selectedBook, dispatch, handleHideCreatorUpdateModal],
  )

  const handleWriterCreate = useCallback(
    async values => {
      setIsUserCreating(true)
      const hashedPassword = await generatePassword()
      const user = {
        ...values,
        email: await generateEmail(),
        password: hashedPassword,
        gender: 'MALE',
      }
      await dispatch(createUserThunk(user))
      await dispatch(fetchUsersThunk())
      handleHideWriterCreateModal()
      setIsUserCreating(false)
    },
    [dispatch, handleHideWriterCreateModal],
  )

  const handleUpdateBookSubmit = useCallback(
    async ({ limited_start, release_at, limited_end, type }) => {
      try {
        const updatedBook = {
          book_id: selectedBook.book_id,
          type,
          limited_start:
            limited_start !== 'Invalid date'
              ? moment(limited_start).format('YYYY-MM-DD')
              : undefined,
          limited_end:
            limited_end !== 'Invalid date'
              ? moment(limited_end).format('YYYY-MM-DD')
              : undefined,
        }

        if (release_at !== 'Invalid date') {
          await dispatch(
            setReleaseBookDateThunk({
              book_id: selectedBook.book_id,
              time: moment(release_at).format('YYYY-MM-DD'),
            }),
          )
        }
        if (limited_end && limited_start)
          await dispatch(updateBookThunk(updatedBook))
        setIsShowSuccessAlert(true)
        await apolloClient.query({
          query: GET_FULL_BOOK,
          variables: {
            book_id: selectedBook.book_id,
          },
          fetchPolicy: 'network-only',
        })
      } catch (error) {
        setIsShowErrorAlert(true)
      }
    },
    [selectedBook, dispatch],
  )

  useEffect(() => {
    if (isShowSuccessAlert || isShowErrorAlert) {
      setTimeout(handleHideAlert, ALERT_FADE_TIMEOUT)
    }
  }, [handleHideAlert, isShowErrorAlert, isShowSuccessAlert])

  useEffect(() => {
    dispatch(fetchUsersThunk())
  }, [dispatch])

  return (
    <>
      <Modal onClose={hideBookModal} isOpen={isOpen} size='lg' isCentered>
        <ModalOverlay />
        <ModalContent w='1000px' minW='1000px'>
          <ModalHeader>
            <ModalNavigation
              currentFlow={currentFlow}
              handleChangeFlow={handleChangeFlow}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentFlow === BOOK_ACTIONS.info && (
              <BookInfo
                handleUpdateBookSubmit={handleUpdateBookSubmit}
                bookPremiumTypes={bookPremiumTypes}
                {...book?.book[0]}
              />
            )}

            {currentFlow === BOOK_ACTIONS.fields && (
              <BookFields isBookLoading={isBookLoading} {...book?.book[0]} />
            )}

            {currentFlow === BOOK_ACTIONS.recommendations && book?.book[0] && (
              <Recommendations book={book?.book[0]} />
            )}

            {currentFlow === BOOK_ACTIONS.author && (
              <>
                {!isShowCreatorUpdateModal && (
                  <Author
                    isBookLoading={isBookLoading}
                    handleShowCreatorUpdateModal={handleShowCreatorUpdateModal}
                    {...book?.book[0]}
                  />
                )}

                {isShowCreatorUpdateModal && !isShowWriterCreateModal && (
                  <BookCreatorModal
                    onHide={handleHideCreatorUpdateModal}
                    usersList={filteredUsersList}
                    handleSearchChange={handleSearchChange}
                    searchFilter={searchFilter}
                    handleBookCreatorUpdate={handleBookCreatorUpdate}
                    handleShowWriterCreateModal={handleShowWriterCreateModal}
                    handleHideCreatorUpdateModal={handleHideCreatorUpdateModal}
                  />
                )}

                {isShowCreatorUpdateModal && isShowWriterCreateModal && (
                  <NewWriterModal
                    isShow={isShowWriterCreateModal}
                    onHide={handleHideWriterCreateModal}
                    onSubmit={handleWriterCreate}
                    isUserCreating={isUserCreating}
                  />
                )}
              </>
            )}

            {currentFlow === BOOK_ACTIONS['resizable cover'] && (
              <BookCover book={book?.book[0]} />
            )}

            {currentFlow === BOOK_ACTIONS['bg cover'] && (
              <BookBgCover book={book?.book[0]} />
            )}

            {currentFlow === BOOK_ACTIONS.audio && (
              <Audio book={book?.book[0]} isBookLoading={isBookLoading} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default BooksModal
