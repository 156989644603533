const { gql } = require('@apollo/client')

export const GET_FULL_BOOK = gql`
  query GetAuthorBook($book_id: String!) {
    book: books(
      where: [{ books: { book_id: $book_id } }]
      cache: 0
      relations: ["creator_id", "genres", "tags", "language", "recommendations"]
    ) {
      book_id
      title
      description
      cover_link
      updated_at
      read_count
      likes
      status
      deleted
      limited_start
      limited_end
      release_at
      type
      is_ongoing
      last_release
      is_new_release
      isListenable
      bgCover
      restrict
      giftsCount
      genres {
        genre_id
        title
      }
      tags {
        title
        tag_id
      }
      language {
        language_id
        keyword
        language
      }
      recommendations {
        book_id
      }
      creator_id {
        user_id
        first_name
        last_name
        published_books_count
        email
        photo_link
      }
    }
  }
`

export const GET_RECOMMENDATION_BOOKS_DATA = gql`
  query GetAuthorBook($recommendations: [JSONObject!]!) {
    books: books(
      where: $recommendations
      cache: 0
      relations: ["creator_id", "language"]
    ) {
      book_id
      title
      cover_link
      status
      deleted
      language {
        language_id
        keyword
        language
      }
      creator_id {
        user_id
        first_name
        last_name
      }
    }
  }
`

export const NEW_UPDATE_RECOMMENDATIONS = gql`
  mutation UpdateRecommendations(
    $book_id: String!
    $recommendations: [CreateRecommendation!]!
  ) {
    result: updateRecommendations(
      book: { book_id: $book_id }
      recommendations: $recommendations
    )
  }
`

export const GET_BOOKS = gql`
  query books($page: Int, $limit: Int) {
    books(page: $page, limit: $limit, cache: 0) {
      book_id
      title
      cover_link
      status
      limited_start
      limited_end
      release_at
      type
      deleted
      recommendations {
        book_id
        title
        cover_link
      }
      creator_id {
        user_id
        first_name
        last_name
      }
    }
  }
`

export const GET_BOOK = gql`
  query authorBook($book_id: String!) {
    book: authorBook(book: { book_id: $book_id }) {
      book_id
      title
      cover_link
      status
      limited_start
      limited_end
      release_at
      type
      deleted
      is_ongoing
      last_release
      is_new_release
      bgCover
      updated_at
      creator_id {
        user_id
        first_name
        last_name
      }
    }
  }
`

export const UPDATE_BOOK = gql`
  mutation updateBookAdmin(
    $book_id: String
    $recommendations: [BookIdentityDto!]
  ) {
    book: updateBookAdmin(
      book: { book_id: $book_id, recommendations: $recommendations }
    ) {
      book_id
      title
      deleted
      recommendations {
        book_id
        title
        cover_link
      }
      creator_id {
        user_id
        first_name
        last_name
      }
    }
  }
`

export const UPDATE_BOOK_FIELDS = gql`
  mutation updateBookAdmin(
    $book_id: String
    $is_ongoing: Boolean
    $last_release: DateTime
  ) {
    book: updateBookAdmin(
      book: {
        book_id: $book_id
        is_ongoing: $is_ongoing
        last_release: $last_release
      }
    ) {
      book_id
      title
      deleted
      is_ongoing
      last_release
    }
  }
`

export const UPDATE_BOOK_BG_COVER = gql`
  mutation updateBookAdmin($book_id: String, $bgCover: String) {
    book: updateBookAdmin(book: { book_id: $book_id, bgCover: $bgCover }) {
      book_id
      title
      deleted
      bgCover
    }
  }
`

export const UPDATE_BOOK_ADMIN_PROTECTED = gql`
  mutation BooksUpdateAdminProtected($book: UpdateBookAdminProtected!) {
    book: booksUpdateAdminProtected(book: $book) {
      book_id
      isListenable
      restrict
      giftsCount
    }
  }
`

export const GET_BOOKS_BY_SEARCH = gql`
  query booksSearch(
    $page: Int
    $limit: Int
    $title: String
    $deleted: Boolean
  ) {
    booksSearch: booksAdminSearch(
      page: $page
      limit: $limit
      alias: "books"
      orders: [{ field: "books.updated_at", type: "DESC" }]
      search: [{ books: { title: $title } }]
      where: [{ books: { status: "APPROVED", deleted: $deleted } }]
      relations: ["creator_id"]
    ) {
      book_id
      title
      cover_link
      status
      limited_start
      limited_end
      release_at
      type
      deleted
      isListenable
      creator_id {
        user_id
        first_name
        last_name
      }
    }
  }
`
export const GET_COMING_SOON_BOOKS_BY_SEARCH = gql`
  query booksComingSoonSearch($page: Int, $limit: Int, $title: String) {
    booksSearch: booksAdminSearch(
      page: $page
      limit: $limit
      alias: "books"
      where: [{ books: { status: "TO_RELEASE" } }]
      search: [{ books: { title: $title } }]
      relations: ["creator_id"]
    ) {
      book_id
      title
      cover_link
      status
      limited_start
      limited_end
      release_at
      type
      creator_id {
        user_id
        first_name
        last_name
      }
    }
  }
`

export const GET_BOOKS_PREMIUM_BY_SEARCH = gql`
  query booksComingSoonSearch($page: Int, $limit: Int, $title: String) {
    booksSearch: booksAdminSearch(
      page: $page
      limit: $limit
      alias: "books"
      where: [{ books: { type: "PREMIUM" } }]
      search: [{ books: { title: $title } }]
      relations: ["creator_id"]
    ) {
      book_id
      title
      cover_link
      status
      limited_start
      limited_end
      release_at
      type
      creator_id {
        user_id
        first_name
        last_name
      }
    }
  }
`

export const GET_LIMITED_BOOKS_BY_SEARCH = gql`
  query booksLimitedSearch($page: Int, $limit: Int, $title: String) {
    booksSearch: booksAdminSearch(
      page: $page
      limit: $limit
      alias: "books"
      where: [
        { books: { limited_start: "is not null", limited_end: "is not null" } }
      ]
      search: [{ books: { title: $title } }]
      relations: ["creator_id"]
    ) {
      book_id
      title
      cover_link
      status
      limited_start
      limited_end
      release_at
      type
      creator_id {
        user_id
        first_name
        last_name
      }
    }
  }
`
