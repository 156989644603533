import React, { useCallback, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { AiFillEdit } from 'react-icons/ai'
import { Box, useToast } from '@chakra-ui/react'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { ImageValidator } from 'utils/image-validator'
import ErrorToast from './ErrorToast'
import {
  GET_BOOKS_BY_SEARCH,
  GET_FULL_BOOK,
  UPDATE_BOOK_BG_COVER,
} from 'services/book/graphql'
import { updateBookBgCover } from 'api/books'
import { useMutation } from '@apollo/client'

const MAX_COVER_WEIGHT = 10485760
const HEIGHT = 3
const WIDTH = 2
const HUNDRED = 100
const RATIO = (WIDTH / HEIGHT) * HUNDRED

const BookBgCover = ({ book }) => {
  const toast = useToast()

  const [updateSelectedBook] = useMutation(UPDATE_BOOK_BG_COVER, {
    refetchQueries: [
      {
        query: GET_FULL_BOOK,
        variables: {
          book_id: book.book_id,
        },
      },
      {
        query: GET_BOOKS_BY_SEARCH,
      },
    ],
  })

  const [url, setUrl] = useState(book?.bgCover)
  const [loading, setLoading] = useState(false)
  const imageInputRef = useRef(null)

  const handleValidateAspectRatio = (dataURL, aspect) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onerror = reject
      img.onload = () => {
        const imgAspect = Math.floor((img.width / img.height) * HUNDRED)
        const requested = Math.floor(aspect)

        if (imgAspect === requested) {
          resolve(true)
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(false)
        }
      }

      img.src = dataURL
    }).catch(exception => {
      return exception
    })
  }

  const onEdit = useCallback(
    async e => {
      setLoading(true)
      const file = e.target.files[0]
      const imageValidator = new ImageValidator(file)

      const isValidImage = imageValidator.checkImageFormat()
      const isValidSize = imageValidator.checkMaxSize(MAX_COVER_WEIGHT)

      if (!isValidImage) {
        toast({
          position: 'top',
          render: ({ onClose }) => (
            <Box mt='61px'>
              <ErrorToast
                onClose={onClose}
                title='This file is in the wrong format'
                reason='Please, choose file with allowed extensions: .jpg, .jpeg, .png'
              />
            </Box>
          ),
          duration: 5000,
        })

        setLoading(false)
        return false
      }

      if (!isValidSize) {
        toast({
          position: 'top',
          render: ({ onClose }) => (
            <Box mt='61px'>
              <ErrorToast
                onClose={onClose}
                title='This file is larger than required'
                reason={`The uploaded file is ${imageValidator.size} MB exceeding the maximum file size of 10MB`}
              />
            </Box>
          ),
          duration: 5000,
        })

        setLoading(false)
        return false
      }

      const imageURL = URL.createObjectURL(file)

      const result = await handleValidateAspectRatio(imageURL, RATIO)

      if (!result) {
        const toastId = 'wrongImageAspectRatioError'

        if (!toast.isActive(toastId)) {
          toast({
            id: toastId,
            position: 'top',
            render: ({ onClose }) => (
              <Box mt='61px'>
                <ErrorToast
                  onClose={onClose}
                  title='Aspect ratio error'
                  reason={`The uploaded file has unsupported aspect ratio`}
                />
              </Box>
            ),
            duration: 5000,
          })
        }

        setLoading(false)
        return false
      }

      try {
        if (file) {
          const formdata = new FormData()
          formdata.append('file', file)
          const { data } = await updateBookBgCover({
            formdata,
            book_id: book.book_id,
          })

          if (data.cdn_link) {
            setUrl(data.cdn_link)
            toast({ title: 'Uploaded', status: 'success', isClosable: true })
            await updateSelectedBook({
              variables: {
                book_id: book.book_id,
                bgCover: data.cdn_link,
              },
            })
          }

          if (imageInputRef.current) {
            imageInputRef.current.value = ''
          }
        } else {
          toast({
            title: 'Failed to upload book cover',
            description: 'No file',
            status: 'error',
            isClosable: true,
          })
        }
      } catch (error) {
        toast({
          title: 'Failed to upload book cover',
          description: error.message,
          status: 'error',
          isClosable: true,
        })
      } finally {
        setLoading(false)
      }

      setLoading(false)
    },
    [toast, book.book_id, updateSelectedBook],
  )

  return (
    <div className={styles.container}>
      <div className={styles.image_container}>
        <img src={url} className={styles.image} alt='book cover' />
        <AiFillEdit className={styles.edit} />
        <label htmlFor='file-input' className={styles.editButton}>
          <input
            type='file'
            id='file-input'
            accept='image/jpg, image/jpeg, image/png'
            className={styles.input}
            onChange={onEdit}
            defaultValue=''
            ref={imageInputRef}
          />
        </label>
      </div>
      {loading && <LoadingSpinner />}
    </div>
  )
}

export default BookBgCover
