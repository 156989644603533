import { Flex, useToast, Accordion } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { API_V2 } from 'constants/apollo'
import { baseScrollBar } from 'constants/scrollbar'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import {
  bannerSections,
  booksSections,
  genresSections,
  tagsSections,
} from 'constants/sections'
import { GET_SECTIONS, UPDATE_SECTION } from 'services/sections/graphql'
import { useLocation, useNavigate } from 'react-router-dom'
import Selection from '../CreateSection/Summary'
import BaseSettings from '../CreateSection/BaseSettings'
import BooksSelector from '../CreateSection/BooksSelector'
import BannersSelector from '../CreateSection/BannersSelector'
import GenresSelector from '../CreateSection/GenresSelector'
import TagsSelector from '../CreateSection/TagsSelector'
import { isEqual } from 'lodash'
import { v4 as uuid } from 'uuid'

const ManageSection = () => {
  const [loading, setLoading] = useState(false)
  const [place, setPlace] = useState('')
  const [type, setType] = useState('')
  const [isAudio, setIsAudio] = useState(null)
  const [isShufflable, setIsShufflable] = useState(null)
  const [screenType, setScreenType] = useState('')
  const [title, setTitle] = useState('')
  const [titleTranslates, setTitleTranslates] = useState([])
  const [description, setDescription] = useState('')
  const [descriptionTranslates, setDescriptionTranslates] = useState([])
  const [selectedStories, setSelectedStories] = useState([])
  const [selectedGenres, setSelectedGenres] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedBanners, setSelectedBanners] = useState([])
  const [targetSectionForTags, setTargetSectionsForTags] = useState({})
  const toast = useToast()
  const navigate = useNavigate()
  const { state } = useLocation()

  const initialData = useMemo(() => {
    if (!state.item) return {}

    const {
      title,
      description,
      type,
      viewType,
      contentType,
      sectionBanners,
      sectionBooks,
      sectionGenres,
      sectionTags,
      descriptionTranslates,
      titleTranslates,
      shufflable,
      isAudio,
    } = state.item

    const shouldAddSections = contentType === 'TAGS' && type === 'ONBOARDING'

    return {
      title,
      description,
      type,
      contentType,
      isAudio,
      viewType,
      isShufflable: shufflable,
      sectionBanners: sectionBanners.map(item => ({ id: item.bannerId })),
      sectionBooks: sectionBooks.map(item => ({ id: item.bookId })),
      sectionGenres: sectionGenres.map(item => ({ id: item.genreId })),
      sectionTags: sectionTags.map(item => ({ id: item.tagId })),
      targetSectionForTags: shouldAddSections
        ? sectionTags.reduce((acc, c) => {
            acc[c.tagId] = c.targetSectionId
            return acc
          }, {})
        : {},
      descriptionTranslates,
      titleTranslates,
    }
  }, [state])

  const canUpdateData = useMemo(() => {
    const shouldAddSections = screenType === 'TAGS' && place === 'ONBOARDING'

    const newData = {
      title,
      description,
      type: place,
      contentType: screenType,
      viewType: type,
      isAudio,
      isShufflable,
      sectionBanners: selectedBanners.map(item => ({ id: item.banner_id })),
      sectionBooks: selectedStories.map(item => ({ id: item.book_id })),
      sectionGenres: selectedGenres.map(item => ({ id: item.genre_id })),
      sectionTags: selectedTags.map(item => ({ id: item.tag_id })),
      targetSectionForTags: shouldAddSections ? targetSectionForTags : {},
      descriptionTranslates: descriptionTranslates.map(item => ({
        locale: item.locale,
        value: item.value,
      })),
      titleTranslates: titleTranslates.map(item => ({
        locale: item.locale,
        value: item.value,
      })),
    }

    return !isEqual(newData, initialData)
  }, [
    initialData,
    title,
    description,
    type,
    place,
    isAudio,
    selectedStories,
    selectedBanners,
    selectedTags,
    selectedGenres,
    titleTranslates,
    descriptionTranslates,
    isShufflable,
    screenType,
    targetSectionForTags,
  ])

  const isBooksSection = useMemo(() => {
    return booksSections.includes(type)
  }, [type])

  const isGenresSection = useMemo(() => {
    return genresSections.includes(type)
  }, [type])

  const isTagsSection = useMemo(() => {
    return tagsSections.includes(type)
  }, [type])

  const isBannersSection = useMemo(() => {
    return bannerSections.includes(type)
  }, [type])

  // #region shufflable
  const toggleShufflable = value => {
    setIsShufflable(value)
  }
  // #endregion

  // #region isAudio
  const toggleAudio = value => {
    setIsAudio(value)
  }
  // #endregion

  // #region translates
  const addTitleTranslation = () => {
    setTitleTranslates(curr => [
      ...curr,
      { key: uuid(), locale: '', value: '' },
    ])
  }

  const handleTitleTranslation = (data, index) => {
    setTitleTranslates(curr => {
      return curr.map((item, ind) => {
        if (ind !== index) {
          return item
        }

        return {
          ...item,
          locale: data.locale,
          value: data.value,
        }
      })
    })
  }

  const handleRemoveTitleTranslation = id => {
    setTitleTranslates(curr => curr.filter(el => el.id !== id))
  }

  const addDscrTranslation = () => {
    setDescriptionTranslates(curr => [
      ...curr,
      { key: uuid(), locale: '', value: '' },
    ])
  }

  const handleDscrTranslation = (data, index) => {
    setDescriptionTranslates(curr => {
      return curr.map((item, ind) => {
        if (ind !== index) {
          return item
        }

        return {
          ...item,
          locale: data.locale,
          value: data.value,
        }
      })
    })
  }

  const handleRemoveDscrTranslation = id => {
    setDescriptionTranslates(curr => curr.filter(el => el.id !== id))
  }

  // #endregion

  // #region books
  const handleAddStory = useCallback(story => {
    setSelectedStories(curr => [...curr, story])
  }, [])

  const handleRemoveStory = useCallback(story => {
    setSelectedStories(curr => {
      const remain = curr.filter(item => story.book_id !== item.book_id)

      return remain
    })
  }, [])

  const handleOnDragBook = useCallback(
    async result => {
      const { source, destination } = result

      if (!destination || source.index === destination.index) return

      const items = [...(selectedStories || [])]
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      setSelectedStories(items)
    },
    [selectedStories],
  )
  // #endregion

  // #region genres
  const handleAddGenre = useCallback(genre => {
    setSelectedGenres(curr => [...curr, genre])
  }, [])

  const handleRemoveGenre = useCallback(genre => {
    setSelectedGenres(curr => {
      const remain = curr.filter(item => genre.genre_id !== item.genre_id)

      return remain
    })
  }, [])

  const handleOnDragGenres = useCallback(
    async result => {
      const { source, destination } = result

      if (!destination || source.index === destination.index) return

      const items = [...(selectedGenres || [])]
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      setSelectedGenres(items)
    },
    [selectedGenres],
  )
  // #endregion

  // #region tags
  const updateTagSectionRelations = useCallback((sectionId, tagId) => {
    setTargetSectionsForTags(curr => ({
      ...curr,
      [tagId]: sectionId,
    }))
  }, [])

  const handleAddTag = useCallback(tag => {
    setSelectedTags(curr => [...curr, tag])
  }, [])

  const handleRemoveTag = useCallback(tag => {
    setSelectedTags(curr => {
      const remain = curr.filter(item => tag.tag_id !== item.tag_id)

      return remain
    })
  }, [])

  const handleOnDragTags = useCallback(
    async result => {
      const { source, destination } = result

      if (!destination || source.index === destination.index) return

      const items = [...(selectedTags || [])]
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      setSelectedTags(items)
    },
    [selectedTags],
  )
  // #endregion

  // #region banners
  const handleAddBanner = useCallback(banner => {
    setSelectedBanners(curr => [...curr, banner])
  }, [])

  const handleRemoveBanner = useCallback(banner => {
    setSelectedBanners(curr => {
      const remain = curr.filter(item => banner.banner_id !== item.banner_id)

      return remain
    })
  }, [])

  const handleOnDragBanner = useCallback(
    async result => {
      const { source, destination } = result

      if (!destination || source.index === destination.index) return

      const items = [...(selectedBanners || [])]
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      setSelectedBanners(items)
    },
    [selectedBanners],
  )
  // #endregion

  const handleClearContent = () => {
    setSelectedStories([])
    setSelectedGenres([])
    setSelectedTags([])
    setSelectedBanners([])
    setTargetSectionsForTags({})
  }

  const toggleScreenType = newPlace => {
    setPlace(newPlace)
  }

  const toggleScreenContentType = newScreenType => {
    setScreenType(newScreenType)
  }

  const toggleContentType = newType => {
    setType(newType)

    const shouldSkipReset =
      (booksSections.includes(newType) &&
        (!type || booksSections.includes(type))) ||
      (genresSections.includes(newType) &&
        (!type || genresSections.includes(type))) ||
      (tagsSections.includes(newType) &&
        (!type || tagsSections.includes(type))) ||
      (bannerSections.includes(newType) &&
        (!type || bannerSections.includes(type)))

    if (!shouldSkipReset) {
      handleClearContent()
    }
  }

  const handleInputTitle = e => {
    setTitle(e.target.value)
  }

  const handleInputDescription = e => {
    setDescription(e.target.value)
  }

  const [updateSection] = useMutation(UPDATE_SECTION, {
    refetchQueries: [GET_SECTIONS],
  })

  const handleSubmit = async () => {
    if (!place || !title.trim() || !type) {
      return
    }

    setLoading(true)
    try {
      const sectionVariables = {
        params: {
          title,
          type: place,
          contentType: screenType,
          viewType: type,
          isAudio,
          shufflable: isShufflable,
        },
        section: {
          id: state.item.id,
        },
      }

      const validTitleTranslations = titleTranslates
        .filter(item => item.locale && item.value)
        .map(item => ({ locale: item.locale, value: item.value }))

      const validDscrTranslations = descriptionTranslates
        .filter(item => item.locale && item.value)
        .map(item => ({ locale: item.locale, value: item.value }))

      if (description) {
        sectionVariables.params.description = description
      }

      if (validTitleTranslations.length > 0) {
        sectionVariables.params.titleTranslates = validTitleTranslations
      }

      if (validDscrTranslations.length > 0) {
        sectionVariables.params.descriptionTranslates = validDscrTranslations
      }

      if (selectedBanners.length > 0) {
        sectionVariables.params.sectionBanners = selectedBanners.map(item => ({
          id: item.banner_id,
        }))
      }

      if (selectedTags.length > 0) {
        const shouldAddSections = type === 'TAGS' && place === 'ONBOARDING'

        sectionVariables.params.sectionTags = selectedTags.map(item => {
          const newItem = {
            id: item.tag_id,
          }

          if (shouldAddSections) {
            newItem.targetSectionId = targetSectionForTags[item.tag_id]
          }

          return newItem
        })
      }

      if (selectedGenres.length > 0) {
        sectionVariables.params.sectionGenres = selectedGenres.map(item => ({
          id: item.genre_id,
        }))
      }

      if (selectedStories.length > 0) {
        sectionVariables.params.sectionBooks = selectedStories.map(item => ({
          id: item.book_id,
        }))
      }

      const {
        data: { section },
      } = await updateSection({
        variables: sectionVariables,
        context: {
          clientName: API_V2,
        },
      })

      if (section.id === state.item.id) {
        toast({ title: 'Updated', status: 'success', isClosable: true })
        navigate(`${window.location.pathname}?active=sections&page=0&slug`)
      } else {
        throw new Error('error')
      }
    } catch (e) {
      toast({
        title: 'Failed',
        description: `Failed to create screen: ${e.message}`,
        status: 'error',
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleClearData = () => {
    handleClearContent()
    setTitle('')
    setPlace('')
    setType('')
    setIsAudio(null)
    setIsShufflable(false)
    setDescription('')
    setDescriptionTranslates([])
    setTitleTranslates([])
  }

  useEffect(() => {
    if (!state?.item) {
      navigate(`${window.location.pathname}?active=sections&page=0&slug`)
    } else {
      const {
        title,
        description,
        type,
        isAudio,
        shufflable,
        contentType,
        viewType,
        sectionBanners,
        sectionBooks,
        sectionGenres,
        sectionTags,
        descriptionTranslates,
        titleTranslates,
      } = state.item

      setTitle(title)
      setIsAudio(isAudio)
      setIsShufflable(shufflable)
      setPlace(type)
      setType(viewType)
      setScreenType(contentType)
      setDescription(description)
      setSelectedStories(
        sectionBooks.map(item => ({
          book_id: item.book.id,
          title: item.book.title,
          cover_link: item.book.coverLink,
          penName: item.book.author.penName,
        })),
      )
      setSelectedGenres(
        sectionGenres.map(item => ({
          genre_id: item.genre.id,
          title: item.genre.title,
        })),
      )
      setSelectedTags(
        sectionTags.map(item => {
          const shouldAddSections =
            contentType === 'TAGS' && type === 'ONBOARDING'
          if (shouldAddSections) {
            updateTagSectionRelations(item.targetSectionId, item.tag.id)
          }

          return {
            tag_id: item.tag.id,
            title: item.tag.title,
            targetSectionId: item.targetSectionId,
          }
        }),
      )
      setSelectedBanners(
        sectionBanners.map(item => ({
          banner_id: item.banner.id,
          cover_url: item.banner.coverUrl,
          title: item.banner.title,
          banner_type: item.banner.type,
        })),
      )
      setDescriptionTranslates(
        descriptionTranslates.map(item => ({ ...item, key: uuid() })),
      )
      setTitleTranslates(
        titleTranslates.map(item => ({ ...item, key: uuid() })),
      )
    }
  }, [state, navigate, updateTagSectionRelations, place])

  return (
    <Flex p='10px 20px 0' direction='column' overflow='hidden'>
      {loading && <LoadingSpinner />}

      <Accordion
        allowMultiple={false}
        w='100%'
        maxH='90vh'
        display='flex'
        flexDirection='column'
        gap='12px'
        overflow='hidden'
      >
        <Flex
          direction='column'
          overflow='auto'
          sx={baseScrollBar}
          gap='12px'
          pb='30px'
        >
          <Selection
            place={place}
            type={type}
            screenType={screenType}
            title={title}
            description={description}
            selectedStories={selectedStories}
            handleRemoveStory={handleRemoveStory}
            selectedGenres={selectedGenres}
            handleRemoveGenre={handleRemoveGenre}
            selectedTags={selectedTags}
            handleRemoveTag={handleRemoveTag}
            selectedBanners={selectedBanners}
            handleRemoveBanner={handleRemoveBanner}
            handleSubmit={handleSubmit}
            handleClearData={handleClearData}
            handleOnDragTags={handleOnDragTags}
            handleOnDragGenres={handleOnDragGenres}
            handleOnDragBook={handleOnDragBook}
            handleOnDragBanner={handleOnDragBanner}
            canUpdateData={canUpdateData}
            isAudio={isAudio}
            isShufflable={isShufflable}
            titleTranslates={titleTranslates}
            descriptionTranslates={descriptionTranslates}
            updateTagSectionRelations={updateTagSectionRelations}
            targetSectionForTags={targetSectionForTags}
            isManager
          />

          <BaseSettings
            place={place}
            type={type}
            screenType={screenType}
            title={title}
            description={description}
            isAudio={isAudio}
            isShufflable={isShufflable}
            toggleAudio={toggleAudio}
            toggleShufflable={toggleShufflable}
            toggleScreenType={toggleScreenType}
            toggleContentType={toggleContentType}
            toggleScreenContentType={toggleScreenContentType}
            handleInputTitle={handleInputTitle}
            handleInputDescription={handleInputDescription}
            titleTranslates={titleTranslates}
            addTitleTranslation={addTitleTranslation}
            handleTitleTranslation={handleTitleTranslation}
            handleRemoveTitleTranslation={handleRemoveTitleTranslation}
            descriptionTranslates={descriptionTranslates}
            addDscrTranslation={addDscrTranslation}
            handleDscrTranslation={handleDscrTranslation}
            handleRemoveDscrTranslation={handleRemoveDscrTranslation}
          />

          {isBooksSection && (
            <BooksSelector
              selectedStories={selectedStories}
              handleAddStory={handleAddStory}
            />
          )}

          {isBannersSection && (
            <BannersSelector
              selectedBanners={selectedBanners}
              handleAddBanner={handleAddBanner}
            />
          )}

          {isGenresSection && (
            <GenresSelector
              selectedGenres={selectedGenres}
              handleAddGenre={handleAddGenre}
            />
          )}

          {isTagsSection && (
            <TagsSelector
              selectedTags={selectedTags}
              handleAddTag={handleAddTag}
            />
          )}
        </Flex>
      </Accordion>
    </Flex>
  )
}

export default ManageSection
